import React, { ReactElement } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import { comingSoonApps } from "../models/models"
import { AppInfoDetails } from "../components/appInfo"

function ComingSoon(): ReactElement {
  return (
    <>
      <Header page={"Coming Soon"} />
      <Layout>
        {comingSoonApps.map((c, i) => (
          <div key={i} className="w-full">
            <AppInfoDetails {...c} />
          </div>
        ))}
      </Layout>
      <Footer />
    </>
  )
}

export default ComingSoon
